@media (max-width: 767px) {

	.header {
		&__bar {
			flex-wrap: wrap;
			align-items: center;
			&__col {
				width: 100%;
				margin-bottom: 10px;
				&:last-child {
					margin-bottom: 0;
				}
				&_auth {
					width: auto;
					order: 1;
					display: flex;
					align-items: flex-end;
					flex-direction: column;
					padding-right: 45px;
					padding-top: 4px;
					.btn {
						margin-top: 1px;
						padding-left: 12px;
						padding-right: 12px;
					}
				}
				&_logo {
					max-width: 100%;
					order: 2;
					margin-bottom: 0;
				}
			}
		}
		&__contacts {
			width: 55%;
			order: 1;
			margin-bottom: 0;
		}
		&__logo {
			width: 100%;
			text-align: center;
			img {
				max-width: 180px;
			}
		}
	}

	.link-contact {
		margin-bottom: 4px;
		margin-right: 0;
		white-space: nowrap;
		flex-wrap: nowrap;
		align-items: center;
		&:last-child {
			margin-bottom: 0;	
		}
		&__icon {
			width: 15px;
			margin-right: 4px;
		}
	}

	.menu {
		flex-direction: column;
		padding-bottom: 10px;
		&>li {
			margin-bottom: 2px;
			&:last-child {
				margin-bottom: 0;	
			}
		}
	}

	.btn-mob-menu {
		display: block;
	}
	
	.calc {
		flex-direction: column;
		&__col {
			width: 100%;
			&_1 {
				margin-bottom: 30px;
			}
		}
	}

	.title-main {
		font-size: 28px;
		line-height: 34px;
	}

	.title-light {
		font-size: 28px;
		line-height: 34px;
	}

	.main {
		&__btn {
			padding-top: 20px;
			padding-left: 0;
		}
		&__title {
			font-size: 28px;
			line-height: 34px;
			margin-bottom: 15px;
		}
		&__list {
			font-size: 18px;
			line-height: 24px;
			&__icon {
				margin-right: 10px;
			}
		}
		&__slide {
			padding-top: 30px;
			padding-bottom: 30px;
		}
	}

	.informers-wrap {
		flex-direction: column;
		align-items: center;
	}

	.item-informer {
		position: relative;
		margin-bottom: 60px;
		&:last-child {
			margin-bottom: 0;	
		}
		&__icon {
			&:after {
				display: none;
			}
		}
		&:after {
			content: "";
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			top: 105%;
			width: 54px;
			height: 32px;
			background: url(../img/arrow-down.svg);
			background-size: 100% 100%;
		}
		&:last-child {
			&:after {
				display: none;
			}
		}
	}

	.cards-wrapper {
		&__col {
			width: 100%;
		}
	}

	.show-all {
		a {
			font-size: 18px;
			line-height: 24px;
		}
	}

	.footer {
		&__main {
			padding-top: 20px;
			padding-bottom: 20px;
		}
		&__row {
			flex-direction: column;
		}
		&__col {
			width: 100%;
			margin-bottom: 10px;
			&:last-child {
				margin-bottom: 0;	
			}
		}
		&__title {
			margin-bottom: 5px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.filter {
		padding-bottom: 20px;
		&__row {
			flex-direction: column;
		}
		&__col {
			padding: 0;
			border: 0;
			width: 100%;
			margin-bottom: 20px;
			&:last-child {
				margin-bottom: 0;	
			}
		}
	}

	.choose-img {
		display: none;
	}

	.choose-row {
		justify-content: center;
	}

	.choose-show {
		border-radius: 12px;
		&__img {
			transform: scale(.8);
			transform-origin: right center;
		}
		&__text {
			padding-left: 0;	
			padding-right: 0;
		}
	}

	.menu {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;	
		height: 100%;
		background: #fff;
		z-index: 250;
		margin: 0;
		padding: 35px 15px;

		overflow-y: auto;

		opacity: .5;
		visibility: hidden;

		transition: .5s;
		transform: translateX(100%);

		&.active {
			opacity: 1;
			visibility: visible;
			transform: translateX(0);
		}

		&>li {
			padding: 0;
			margin: 0 0 15px;
		}
	}


	.hide-768 {
		display: none;
	}
	
}