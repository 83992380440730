/****************************************************
main page
****************************************************/

.header {
	padding-top: 6px;
	padding-bottom: 1px;
	&__logo {
		width: 260px;
		flex-shrink: 0;
	}
	&__bar {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 -10px;
		padding-bottom: 10px;
		&__col {
			padding-left: 10px;
			padding-right: 10px;
			box-sizing: border-box;
			&_auth {
				flex-shrink: 0;
			}
		}
	}
	&__contacts {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}
}

.link-contact {
	display: flex;
	flex-wrap: wrap;
	font-size: 13px;
	color: #0a0a0a;
	text-decoration: none;
	margin-right: 10px;
	&:last-child {
		margin-right: 0;	
	}
	&__icon {
		width: 23px;
		margin-right: 7px;
		line-height: 0;
	}
}

.link-reg {
	text-decoration: none;
	font-weight: 600;
	color: #434343;
	margin-right: 5px;
	&:hover {
		text-decoration: underline;	
	}
}

.menu {
	list-style: none;
	display: flex;
	flex-wrap: wrap;
	margin: 0 -16px;
	&>li {
		padding-left: 16px;
		padding-right: 16px;
		&>a {
			color: #0a0a0a;
			font-size: 14px;
			font-weight: 600;
			text-decoration: none;
			&:hover {
				color: lighten(#0a0a0a, 65%);	
			}
		}
	}
}

.main {
	background: url(../img/bg-main.jpg) no-repeat center 0;
	background-size: cover;
	color: #fff;
	&__title {
		font-size: 60px;
		line-height: 62px;
		font-weight: 700;
		margin-bottom: 37px;
	}
	&__list {
		list-style: none;
		font-size: 30px;
		line-height: 38px;
		font-weight: 300;
		&>li {
			position: relative;
			display: flex;
			align-items: flex-start;
			margin-bottom: 5px;
			&:last-child {
				margin-bottom: 0;	
			}
		}

		&__icon {
			width: 15px;
			height: 15px;
			border-radius: 100%;
			background: #243a4b;
			position: relative;
			margin-top: 10px;
			margin-right: 22px;
			&:before {
				content: "";
				position: absolute;
				right: -5px;
				top: -3px;
				width: 18px;
				height: 18px;
				background: url(../img/tick.svg) no-repeat;
			}
		}
	}
	&__slide {
		background-repeat: no-repeat;
		background-position: center 0;
		padding-top: 75px;
		padding-bottom: 60px;
		background-color: #dcdee0;
	}

	&__btn {
		padding: 44px 0 0 107px;
	}

	.slick-arrow {
		position: absolute;
		top: 50%;
		width: 33px;
		height: 52px;
		background: url(../img/slider-arrow.svg) no-repeat;
		background-size: cover;
		font-size: 0;
		z-index: 50;
	}
	.slick-prev {
		left: calc(50% - 420px - 200px);
		transform: translate(-50%, -50%) rotate(180deg);
	}
	.slick-next {
		left: calc(50% + 420px + 200px);
		transform: translate(-50%, -50%);
	}
}

.support {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	font-size: 18px;
	line-height: 24px;
	font-weight: 300;
	color: #0a0a0a;
	padding: 13px 0;
	&__icon {
		width: 35px;
		margin-right: 7px;
	}
	a {
		color: #0a0a0a;
		text-decoration: none;
		margin-right: 4px;
	}
}

.title-main {
	text-align: center;
	text-transform: uppercase;
	font-size: 40px;
	line-height: 46px;
	color: #0a0a0a;
	margin-bottom: 27px;
	font-weight: 700;
}

.title-light {
	color: #0a0a0a;
	font-size: 36px;
	line-height: 42px;
	font-weight: 300;
	text-align: center;
	margin-bottom: 40px;
	span {
		font-weight: 400;
	}
}

.item-informer {
	&__icon {
		position: relative;
		margin-bottom: 10px;
	}
	&__text {
		color: #0a0a0a;
		font-size: 16px;
	}
}

.subtitle {
	font-size: 16px;
	font-weight: 600;
	color: #090909;
	text-align: center;
}

.show-all {
	text-align: center;
	a {
		font-size: 30px;
		font-weight: 700;
	}
}


.section-calc {
	background-image: linear-gradient(to bottom, #dcdee0 0%, #fff 100%);
	padding: 17px 0 39px;
}

.calc {
	display: flex;
	justify-content: center;
	margin: 0 -15px 36px;
	&__col {
		padding-left: 15px;
		padding-right: 15px;
		box-sizing: border-box;
		&_1 {
			width: 48%;
		}
		&_2 {
			width: 46%;			
		}
	}

	&__label {
		font-size: 18px;
		line-height: 24px;
		margin-bottom: 6px;
	}

	&__block {
		position: relative;
		margin-bottom: 7px;
		&:last-child {
			margin-bottom: 0;	
		}
		&_platform {
			margin-bottom: 24px;
		}

		&__num {
			position: absolute;
			right: 100%;
			bottom: 0;
			&.select-num {
				margin-right: 4px;
			}
			.selectize-input {
				background: transparent;
			}
		}
		.selectize-control {
			margin-bottom: 10px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	&__platform {
		z-index: 10;
		position: relative;
	}
}

.select-bg-marker {
	position: absolute;
	left: 0;
	top: 0;
	background: #264c7a;
	height: 38px;
	min-width: 50px;
	border-radius: 35px;
	transition: .3s;
	z-index: -1;
}

.select-blocks {
	display: flex;
	flex-wrap: wrap;
}

.select-num {
	width: 45px;
	margin-right: 14px;
	.selectize-input {
		padding-left: 7px;
		padding-right: 5px;
		text-align: center;
		font-size: 18px;
	}
	.selectize-control.single .selectize-input:before {
		right: 8px;
	}
}

.select-ip {
	width: 135px;
	.selectize-input {
		font-size: 18px;
	}
	.item {
		text-align: center;
		width: 100%;
	}
}

.row-select {
	border: 1px solid #264c7a;
	border-radius: 35px;
	display: flex;
	flex-wrap: wrap;
	margin-top: -1px;
	&:first-child {
		margin-top: 0;	
	}
}

.choose-row {
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
}

.choose-show {
	border: 1px solid #264c7a;
	background: #fff;
	border-radius: 22px;
	width: 130px;
	margin-right: 5px;
	margin-bottom: 10px;
	box-sizing: border-box;
	padding: 3px 5px;
	&:last-child {
		margin-right: 0;	
	}
	&__img {
		height: 65px;
		display: flex;
		justify-content: flex-end;
		.img-cpu {
			margin-right: -50px;
		}
		.img-ram {
			margin-right: -27px;
		}
		.img-hdd {
			margin-right: -61px;
		}
		img {
			position: relative;
			&:nth-child(1) {
				z-index: 10;
			}
			&:nth-child(2) {
				z-index: 9;
			}
			&:nth-child(3) {
				z-index: 8;
			}
			&:last-child {
				margin-right: 0;	
			}
		}
	}
	&__title {
		font-size: 10px;
		line-height: 12px;
		margin-bottom: 6px;
		text-align: center;
	}
	&__text {
		font-size: 12px;
		line-height: 14px;
		padding-left: 10px;
		padding-right: 10px;
		text-align: center;
	}
}
.choose-show__img img:not(.is-active){opacity: 0}

.choose-img {
	margin-top: 65px;
	margin-bottom: 23px;
}

.choose-price {
	display: flex;
	flex-wrap: wrap;
	margin: 18px 0 24px;
	.price {
		margin-right: 10px;
		margin-bottom: 4px;
	}
	&__text {
		color: #0a0a0a;
		font-size: 18px;
		line-height: 20px;
		margin-top: -4px;
	}
}

.choose-btn {
	text-align: center;
	margin-top: 45px;
	.btn {
		width: 295px;
		max-width: 100%;
	}
}

.price {
	font-size: 30px;
	color: #ff5e3a;
	font-weight: 700;
	.price-rouble {
		font-size: 24px;
		line-height: 24px;
		font-weight: 400;
	}
}

.informers-wrap {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.item-informer {
	width: 220px;
	margin-right: 13.6%;
	&:nth-child(3n) {
		margin-right: 0;
		.item-informer {
			&__icon {
				&:after {
					display: none;
				}
			}
		}
	}
	&__icon {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		height: 190px;
		box-sizing: border-box;
		padding: 50px;
		margin-bottom: 4px;
		background: url(../img/circle-outline.svg) no-repeat;
		img {
			max-height: 100%;
		}
		&:after {
			content: "";
			position: absolute;
			right: -131px;
			bottom: 0px;
			width: 186px;
			height: 58px;
			background: url(../img/arrow-right.svg);
		}
	}
	&__text {
		font-size: 16px;
		line-height: 24px;
		color: #0a0a0a;
	}
}









.item-card {
	margin-bottom: 25px;
	border-radius: 40px;
	background: #f3f3f3;
	box-shadow: 2px -2px 8px 0 rgba(0,0,0,.6);
	box-sizing: border-box;
	padding: 10px 0;
	&__img {
		position: relative;
		line-height: 0;
		height: 45px;
		text-align: center;
		margin-bottom: 16px;
		img {
			max-height: 100%;
		}
	}
	&__title {
		text-align: center;
		color: #263a7a;
		font-size: 18px;
		line-height: 24px;
		font-weight: 700;
		margin-bottom: 8px;
	}
	&__list {
		list-style: none;
		color: #263a7a;
		font-size: 16px;
		line-height: 24px;
		padding: 0 10px;
		&>li {
			display: flex;
		}
		&__icon {
			width: 20px;
			margin-right: 10px;
			flex-shrink: 0;
			img {
				max-width: 100%;
			}
		}
	}
	&__price {
		display: flex;
		align-items: flex-end;
		justify-content: center;
		margin-bottom: 12px;
		.price {
			font-size: 48px;
			line-height: 48px;
			margin-right: 4px;
		}
	}
	&__btn {
		padding-left: 37px;
		padding-right: 37px;
		.btn-main {
			height: 35px;
			width: 100%;
			font-size: 18px;
		}
	}
}

.cards-wrapper {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -12px 25px;
	&__col {
		box-sizing: border-box;
		width: 33.33%;
		padding-left: 12px;
		padding-right: 12px;
	}
}

.section-items {
	padding-top: 53px;
	padding-bottom: 30px;
}

.price-note {
	font-size: 10px;
	line-height: 14px;
	font-weight: 700;
	color: #020202;
}

.price-rouble {
	font-size: 48px;
	line-height: 48px;
	color: #ff5e3a;
	font-family: "Cambria";
}


.section-assembly {
	box-shadow: -3px 0 10px 1px rgba(0,0,0,.45);
	&__top {
		background: #f3f3f3;
		padding-top: 12px;
		padding-bottom: 22px;
		.title-main {
			margin-bottom: 13px;
		}
	}
}

.filter {
	background: url(../img/mask-bg.png) no-repeat center 0;
	background-size: cover;
	color: #fff;
	padding-top: 30px;

	&__label {
		font-size: 14px;
		width: 105px;
		flex-shrink: 0;
	}
	&__block {
		margin-bottom: 20px;
		display: flex;	
		&:last-child {
			margin-bottom: 0;
		}
	}
	&__row {
		display: flex;
	}
	&__col {
		width: 33.3%;
		box-sizing: border-box;
		border-right: 2px solid #000;
		padding-left: 10px;
		padding-right: 10px;
		padding-bottom: 30px;
		&:first-child {
			padding-left: 0;	
		}
		&:last-child {
			padding-right: 0;
		}
		&:nth-child(3n) {
			border-right: 0;
		}
	}
	&__main {
		position: relative;
		flex-grow: 1;
	}
}



.footer {
	&__main {
		padding-top: 30px;
		padding-bottom: 42px;
		background: url(../img/mask-bg.png) no-repeat center 0;
		background-size: cover;
		color: #fff;
	}
	&__logo {
		max-width: 60px;
	}
	&__title {
		color: #ff5f3b;
		font-size: 14px;
		font-weight: 700;
		margin-bottom: 20px;
	}
	&__menu {
		list-style: none;
		&>li {
			&>a {
				text-decoration: none;
				color: #fff;
				font-size: 14px;
				&:hover {
					text-decoration: underline;	
				}
			}
		}
	}
	&__row {
		display: flex;
		justify-content: space-between;
		margin: 0 -15px;
	}
	&__col {
		padding: 0 15px;
		box-sizing: border-box;
	}

	&__bottom {
		padding: 12px 0;
		font-size: 12px;
		line-height: 14px;
		font-weight: 600;
		color: #565656;
		background: #fff;
	}
}



.irs {
	margin-bottom: 12px;
	&>.irs {
		margin-top: 0;
		margin-bottom: 0;
	}
}

.irs-line {
	height: 4px;
	background: #0b0b0b;
	border-radius: 8px;
	box-shadow: 0 -1px 0 0 #4b4d5a;
}

.irs-handle {
	cursor: pointer;
	bottom: -2px;
	width: 19px;
    height: 21px;
    border-radius: 100%;
	background: url(../img/handle.png) no-repeat;
	box-sizing: border-box;
	cursor: pointer;
	&.from {
		margin-left: -2px;
	}
}

.irs-bar {
	height: 3px;
	background: #7acbfb;
	box-shadow: 0 0 0 1px #3a9dd4, inset 0 0 1px 0 #3a9dd4;
	top: 0;
}

.irs-from, .irs-to, .irs-single {
	font-size: 10px;
	top: -30px;
	margin-left: -4px;
}

.range-price-wrap {
	max-width: 430px;
}

.range {
	margin-bottom: -10px;
	margin-right: 4px;
}

.range-wrapper {
	margin-top: 10px;
	margin-bottom: -10px;
	margin-right: 15px;
	&:last-child {
		margin-right: 0;	
	}
}


.checkbox {
  display: flex;
  position: relative;
  margin-bottom: 7px;
  &__main {
  	display: inline-block;
    cursor: pointer;
    font-size: 10px;
    line-height: 12px;
    padding-left: 17px;
  }
  label {
  	display: flex;
  }
  input {
    top: 0;
    left: 0;
    z-index: 1;
    cursor: pointer;
    opacity: 0;
    position: absolute;
    &:checked + .checkbox__elem {
		&:before {
			opacity: 1;
		}
    }
  }
  &__elem {
		flex-shrink: 0;
		position: absolute;
		left: 0;
		top: 0;
		cursor: pointer;
		width: 10px;
		height: 10px;
		border-radius: 100%;
		box-sizing: border-box;
		background: #000;
		&:before {
			content: "";
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
			opacity: 0;
			width: 8px;
			height: 8px;
			border-radius: 100%;
			background: #fff;
			color: red;
			transition: .3s;
		}
  	}
}

.checkboxes-row {
	display: flex;
	flex-wrap: wrap;
	margin-left: -10px;
	margin-right: -10px;
	margin-top: 5px;
	&__col {
		padding-left: 10px;
		padding-right: 10px;
		box-sizing: border-box;
		width: 50%;
	}
}

.align-bottom {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.progressbar {
    width:79px;
    height: 79px;
    background: url(../img/progress-inner.png) no-repeat;
    position: absolute;
    right: 17px;
    bottom: -20px;
	&__circle {
	    width: 100%;
	    margin: 0 auto;
	    display: inline-block;
	    position: relative;
	    text-align: center;
	    border-radius: 100%;
	    margin: 13px 0 0;
	    transform: rotate(-180deg);
	}
	&__circle canvas {
	    vertical-align: middle;
	}
	&__label {
		font-size: 20px;
		line-height: 20px;
		color: #adaeb2;
		font-weight: 700;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
		font-family: 'DIN Pro Cond';
	}

}