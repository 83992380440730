@media (max-width: 1400px) {
	.main {
		.slick-prev {
			left: 15px;
			transform: translateY(-50%) rotate(180deg);
		}

		.slick-next {
			left: auto;
			right: 15px;
			transform: translateY(-50%);
		}
	}

}

@media (max-width: 969px) {
	.header {
		&__logo {
			width: 180px;
		}
	}

	.container {
		padding-left: 15px;
		padding-right: 15px;
	}

	.menu {
		margin-left: -10px;
		margin-right: -10px;
		&>li {
			padding-left: 10px;
			padding-right: 10px;
		}
	}

	.main {
		&__title {
			font-size: 40px;
			line-height: 46px;
		}
		&__list {
			font-size: 24px;
			line-height: 30px;
		}
		&__slide {
			padding-top: 50px;
			padding-bottom: 50px;
		}
	}

	.calc {
		&__col {
			&_1 {
				width: 50%;
			}
			&_2 {
				width: 50%;
			}
		}
	}

	.item-informer {
		margin-right: 5.2%;
		&__icon {
			&:after {
				right: -45px;
				width: 100px;
				height: 40px;
				background-size: 100% 100%;
			}
		}
	}
	
	.cards-wrapper {
		margin-bottom: 0px;
		&__col {
			width: 50%;
		}
	}

	.show-all {
		a {
			font-size: 22px;
			line-height: 26px;
		}
	}

	.select-num-cpu {
		position: static;
		flex-shrink: 0;
	}

	.select-cpu {
		display: flex;
	}







}