/****************************************************
buttons
****************************************************/
.btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	transition: .5s;
	text-decoration: none;
	cursor: pointer;	

	&-choose {
		color: #fff;
		background: #e75f3f;
		border-bottom: 3px solid #963b2a;
		padding: 10px 25px;
		text-shadow: -1px -1px 0 #e75f3f;
		font-weight: 700;
		height: 57px;
		border-radius: 35px;
		letter-spacing: .6px;
	}

	&-main {
		height: 45px;
		background: #264c7a;
		color: #fff;
		font-size: 18px;
		line-height: 20px;
		font-weight: 700;
		border-radius: 12px;
		box-shadow: 5px 5px 5px 0 rgba(0,0,0,.3);
		padding: 5px 15px;

		&_mod-mini {
			height: 25px;
			font-weight: 600;
			font-size: 14px;
			line-height: 16px;
			border-radius: 20px;
			box-shadow: 3px 3px 5px 0 rgba(0,0,0,.3);
			padding-left: 23px;
			padding-right: 23px;
		}

		&:hover {
			background: #2f63a1;
			box-shadow: 3px 3px 15px 0 rgba(0,0,0,.5);
		}
	}

	&-select {
		flex-grow: 1;
		width: 33.3%;
		span {
			text-align: center;
			min-height: 36px;
			color: #010101;
			font-size: 18px;
			line-height: 24px;
			padding-left: 15px;
			padding-right: 15px;
			border-radius: 35px;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			transition: .3s;
		}
		input {
			&:checked {
				&+span {
					/*background: #264c7a;
					color: #c3ccea;*/

				}
			}
		}
		&.active {
			span {
				color: #fff;
			}
		}
	}

	&-add {
		border: 1px dashed #000;
		color: #000;
		height: 36px;
		width: 100%;
		font-weight: 300;
		font-size: 18px;
		line-height: 18px;
		background: #f3f3f3;
		border-radius: 35px;
	}

	&-mob-menu {
		display: none;
		position: absolute;
		right: 15px;
		top: 16px;
		width: 24px;
		height: 16px;
		cursor: pointer;
		z-index: 300;
		& span,
		&:before,
		&:after{
			content: "";
			display: block;
			width: 25px;
			height: 2px;
			background-color: black;
			position: absolute;
			left: 0;
			transition: all 300ms ease-in-out;
			border-radius: 4px;
		}

		&:before{
			top:0px;
		}
		& span{
			top:7px;
			opacity:1;
		}
		&:after{
			top:14px;
		}
		&.active {
			span {
				opacity:0;
				top:6px;
				width: 22px;
				transform:rotate(45deg);
			}
			&:before {
				width:22px;
				top:6px;
				left: 0px;
				transform:rotate(45deg);
			}
			&:after {
				width:22px;
				top:6px;
				left: 0px;
				transform:rotate(-45deg);
			}
		}
	}

}

